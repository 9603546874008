import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@exb/auth';
import { SortingFilterStoreService } from '@exb/document';
import { LoggingService } from '@exb/logging';
import { TranslocoService } from '@jsverse/transloco';
import { EMPTY, catchError } from 'rxjs';

@Component({
  selector: 'exb-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  readonly languages = [
    { label: 'English', value: 'en' },
    { label: 'Deutsch', value: 'de' },
  ];

  form = new FormGroup({
    language: new FormControl(this.translocoService.getActiveLang()),
  });

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly translocoService: TranslocoService,
    private readonly sortingFilterStoreService: SortingFilterStoreService,
    private readonly loggingService: LoggingService,
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ language }) => {
      this.translocoService.setActiveLang(language!);
      // TODO: Replace with a backend persistence
      localStorage.setItem('lang', language!);
    });
  }

  logout() {
    this.authenticationService
      .logout()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(error => {
          this.loggingService.error('Error on logout', error);
          return EMPTY;
        }),
      )
      .subscribe({
        complete: () => {
          this.sortingFilterStoreService.clearSFData();
          this.router.navigateByUrl('/signin', { replaceUrl: true });
        },
      });
  }
}
