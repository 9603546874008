import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SortingFilterStoreService } from '@exb/document';

const DEFAULT_SF_PARAMS = {
  sortBy: 'createdAt',
  sortDirection: 'desc',
};

export const canActivateDocManagementWithNoParams: CanActivateFn = (route, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const sortingFilterStoreService = inject(SortingFilterStoreService);
  const solutionId = route.paramMap.get('solutionId')!;
  const storedParams = sortingFilterStoreService.getDocumentListSFDataBySolutionId(solutionId);
  const docListSFParams = storedParams && Object.keys(storedParams).length > 0 ? storedParams : DEFAULT_SF_PARAMS;

  const [url, queryParams] = state.url.split('?');
  const params = new URLSearchParams(queryParams);
  if (!params.has('sortBy')) {
    for (const key in docListSFParams) {
      params.set(key, docListSFParams[key]);
    }
    return router.parseUrl(`${url}?${params.toString()}`);
  }
  return true;
};

export const canActivateDocSetManagementWithNoParams: CanActivateFn = (route, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const sortingFilterStoreService = inject(SortingFilterStoreService);
  const solutionId = route.paramMap.get('solutionId')!;
  const storedParams = sortingFilterStoreService.getDocumentSetSFDataBySolutionId(solutionId);
  const docSetSortParams = storedParams && Object.keys(storedParams).length > 0 ? storedParams : DEFAULT_SF_PARAMS;

  // Regex to check if any query params are present
  if (!state.url.match(/\?.+$/)) {
    const params = new URLSearchParams(docSetSortParams as Record<string, string>).toString();
    return router.parseUrl(`${state.url}?${params}`);
  }
  return true;
};
