import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@exb/auth';
import { SortingFilterStoreService } from '@exb/document';
import { Translation, TranslocoService } from '@jsverse/transloco';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, switchMap } from 'rxjs';

@Component({
  selector: 'exb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  selectPrimeNgTranslationOnLangChange$?: Observable<Translation>;

  constructor(
    private readonly primeNGConfig: PrimeNGConfig,
    private readonly translocoService: TranslocoService,
    private readonly authenticationService: AuthenticationService,
    private readonly sortingFilterStoreService: SortingFilterStoreService,
  ) {}

  ngOnInit(): void {
    dayjs.extend(customParseFormat);
    this.authenticationService.loggedInUserId$.subscribe(userId => {
      if (userId) {
        this.sortingFilterStoreService.getSFDataFromLocalStore(userId);
      }
    });
    // no unsubscribe needed since the component here is the app
    this.selectPrimeNgTranslationOnLangChange$ = this.translocoService.langChanges$.pipe(
      switchMap(() => this.translocoService.selectTranslateObject('primeng')),
    );
    this.selectPrimeNgTranslationOnLangChange$.subscribe(result => {
      this.primeNGConfig.setTranslation(result);
    });
  }
}
