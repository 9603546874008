import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EndTruncateComponent } from '@exb/components';
import { TranslocoModule } from '@jsverse/transloco';

export interface WorkAreaStatus {
  id: string;
  label: string;
  isAvailable: boolean;
}

@Component({
  selector: 'exb-solution-card-body',
  standalone: true,
  imports: [CommonModule, TranslocoModule, EndTruncateComponent],
  templateUrl: './solution-card-body.component.html',
  styleUrls: ['./solution-card-body.component.scss'],
})
export class SolutionCardBodyComponent {
  @Input()
  workAreaStatuses!: WorkAreaStatus[];

  @Input()
  solutionDescription = '';

  @Input()
  descriptionClampedLines = 3;
}
