import { CommonModule } from '@angular/common';
import { Component, DestroyRef, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  ConfirmDialogOptions,
  ConfirmDialogService,
  EmptyStateMessage,
  EmptyStatusComponent,
  ToastsMessageService,
} from '@exb/components';
import { DEMEditorComponent, DemEditorModule } from '@exb/dem-editor';
import {
  DocumentFilter,
  DocumentService,
  DocumentSet,
  DocumentSetSelectorModule,
  DocumentSetService,
  DocumentSort,
  IDocument,
} from '@exb/document';
import { DocumentViewModule } from '@exb/document-view';
import { LoggingService } from '@exb/logging';
import { HasOneOfThesePermissionsDirective, PermissionsService } from '@exb/permissions';
import { SolutionStateService } from '@exb/solution';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { isEqual } from 'lodash-es';
import { ConfirmEventType } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { SplitterModule } from 'primeng/splitter';
import { ToggleButtonModule } from 'primeng/togglebutton';
import {
  NEVER,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';

@Component({
  selector: 'exb-dem',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DemEditorModule,
    DocumentSetSelectorModule,
    DocumentViewModule,
    EmptyStatusComponent,
    HasOneOfThesePermissionsDirective,
    FormsModule,
    RouterModule,
    SplitterModule,
    ToggleButtonModule,
    TranslocoModule,
  ],
  templateUrl: './dem.component.html',
  styleUrls: ['./dem.component.scss'],
})
export class DEMComponent {
  protected readonly currentDocumentId$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);
  protected readonly currentDocumentSetId$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(
    1,
  );

  protected readonly currentDocument$: Observable<IDocument | undefined>;
  protected readonly currentDocumentSet$: Observable<DocumentSet | undefined>;

  private readonly documentIds$: Observable<string[]>;
  protected readonly currentDocumentIndex$: Observable<number | undefined>;
  protected readonly availableDocumentsCount$: Observable<number>;

  private readonly switchDocumentEvent$ = new Subject<'previous' | 'next'>();

  private filter$: Observable<DocumentFilter>;
  private sort$: Observable<DocumentSort>;

  @ViewChild(DEMEditorComponent)
  demEditor!: DEMEditorComponent;

  emptyStatus: EmptyStateMessage = {
    header: this.translocoService.translate('dem.noDocuments.header'),
    description: this.translocoService.translate('dem.noDocuments.description'),
    action: {
      label: this.translocoService.translate('dem.noDocuments.buttonLabel'),
      icon: '',
    },
  };

  unsavedGDDialogConfig: Partial<ConfirmDialogOptions> = {
    header: this.translocoService.translate('unsavedChangesConfirmDialog.header'),
    message: this.translocoService.translate('unsavedChangesConfirmDialog.GoldDataInitialization.message'),
    icon: 'material-icons-round mi-warning icon-warning-standalone',
    level: 'warning',
  };

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly documentService: DocumentService,
    private readonly documentSetService: DocumentSetService,
    private readonly confirmationDialogService: ConfirmDialogService,
    private readonly translocoService: TranslocoService,
    private readonly toastsMessageService: ToastsMessageService,
    private readonly loggingService: LoggingService,
    protected readonly solutionState: SolutionStateService,
    public readonly permissionsService: PermissionsService,
  ) {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const pathParams = this.activatedRoute.snapshot.params;
    this.currentDocumentId$.next(queryParams['documentId'] || pathParams['documentId']);
    this.currentDocumentSetId$.next(queryParams['documentSetId'] || pathParams['documentSetId']);

    this.currentDocument$ = this.currentDocumentId$.pipe(
      switchMap(documentId => (documentId ? this.documentService.getDocument(documentId) : of(undefined))),
      catchError(error => {
        this.loggingService.error('Error getting document', error);
        this.router.navigate(['document-not-found'], { replaceUrl: true });
        return NEVER;
      }),
      shareReplay(1),
    );
    this.currentDocumentSet$ = this.currentDocumentSetId$.pipe(
      switchMap(documentSetId =>
        documentSetId ? this.documentSetService.getDocumentSet(documentSetId) : of(undefined),
      ),
      shareReplay(1),
    );

    this.filter$ = this.activatedRoute.data.pipe(
      map(data => {
        const defaultFilter: DocumentFilter = { onlySupportedDocs: true, hasAtLeastOnePage: true };
        return { ...defaultFilter, ...data['filter'] } as DocumentFilter;
      }),
    );
    this.sort$ = this.activatedRoute.data.pipe(
      map(data => {
        const defaultSort: DocumentSort = { field: 'createdAt', direction: 'desc' };
        return (data['sort'] as DocumentSort) || defaultSort;
      }),
    );

    this.documentIds$ = combineLatest([this.filter$, this.sort$, this.currentDocumentSetId$]).pipe(
      distinctUntilChanged(isEqual),
      switchMap(([filter, sort, documentSetId]) => {
        return documentSetId
          ? documentService.watchDocumentIdsInDocumentSet(documentSetId, filter, sort)
          : documentService.watchDocumentIds(filter, sort);
      }),
      shareReplay(1),
    );

    this.availableDocumentsCount$ = this.documentIds$.pipe(map(documentIds => documentIds.length));
    this.currentDocumentIndex$ = combineLatest([this.currentDocument$, this.documentIds$]).pipe(
      map(([document, documentIds]) => {
        if (document && documentIds.includes(document.id)) {
          return documentIds.indexOf(document.id);
        }
        return undefined;
      }),
    );

    this.switchDocumentEvent$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(
          this.documentIds$,
          this.currentDocumentIndex$.pipe(filter((index): index is number => index !== undefined)),
        ),
        map(([direction, documentIds, currentDocumentIndex]) => {
          const nextDocumentIndex = (currentDocumentIndex + (direction === 'previous' ? -1 : 1)) % documentIds.length;
          return documentIds.at(nextDocumentIndex);
        }),
        filter((documentId): documentId is string => !!documentId),
        tap(newDocumentId => this.currentDocumentId$.next(newDocumentId)),
        switchMap(newDocumentId => {
          const urlTree = this.router.createUrlTree([], {
            queryParams: { documentId: newDocumentId },
            queryParamsHandling: 'merge',
            preserveFragment: true,
          });
          return this.router.navigateByUrl(urlTree, { replaceUrl: true });
        }),
      )
      .subscribe();
  }

  onDocumentSetSelected(documentSetId: string | undefined) {
    this.currentDocumentSetId$.next(documentSetId);
    const firstDocumentId$ = combineLatest([this.filter$, this.sort$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      first(),
      switchMap(([filter, sort]) => {
        return documentSetId
          ? this.documentService.getFirstDocumentIdInDocumentSet(documentSetId, filter, sort)
          : this.documentService.getFirstDocumentId(filter, sort);
      }),
    );
    firstDocumentId$.subscribe(firstDocumentId => {
      if (firstDocumentId) {
        this.currentDocumentId$.next(firstDocumentId);
        const urlTree = this.router.createUrlTree([], {
          queryParams: { documentId: firstDocumentId, documentSetId },
          queryParamsHandling: 'merge',
          preserveFragment: true,
        });
        this.router.navigateByUrl(urlTree);
      } else {
        this.toastsMessageService.showError(this.translocoService.translate('dem.noSupportedDocsToast'));
      }
    });
  }

  goBack() {
    const { queryParamMap, data } = this.activatedRoute.snapshot;
    const onRouteThatShowsDocumentSetSelector = !!data['shouldShowDocumentSetSelector'];
    const solutionHasDocumentSets = !!this.solutionState.solution.documentSetCount;
    const isShowingDocument = queryParamMap.has('documentId');

    if (onRouteThatShowsDocumentSetSelector && solutionHasDocumentSets && isShowingDocument) {
      this.goBackToDocumentSetSelector();
    } else {
      this.router.navigate(['..'], { relativeTo: this.activatedRoute });
    }
  }

  private goBackToDocumentSetSelector() {
    this.currentDocumentId$.next(undefined);
    this.currentDocumentSetId$.next(undefined);
    const urlTree = this.router.createUrlTree([], {
      queryParams: { documentSetId: undefined, documentId: undefined },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    });
    this.router.navigateByUrl(urlTree, { replaceUrl: true });
  }

  goToPreviousDocument() {
    this.switchDocumentEvent$.next('previous');
  }

  goToNextDocument() {
    this.switchDocumentEvent$.next('next');
  }

  canLeavePage(): Observable<boolean> {
    if (!this.demEditor.hasUnsavedData) {
      return of(true);
    }

    return this.demEditor.isGoldDataInitialized$.pipe(
      switchMap(goldDataInitialization => {
        if (goldDataInitialization) {
          return this.confirmationDialogService.confirmUnsavedChanges(this.unsavedGDDialogConfig).pipe(
            switchMap(confirmEventType => {
              return this.onDialogActions(confirmEventType);
            }),
          );
        }
        return this.confirmationDialogService.confirmUnsavedChanges().pipe(
          switchMap(confirmEventType => {
            return this.onDialogActions(confirmEventType);
          }),
        );
      }),
    );
  }

  onDialogActions(confirmEventType: ConfirmEventType): Observable<boolean> {
    switch (confirmEventType) {
      case ConfirmEventType.ACCEPT:
        return this.demEditor.saveDEM();
      case ConfirmEventType.REJECT:
        this.demEditor.resetDEM();
        this.toastsMessageService.showSuccess(this.translocoService.translate('dem.discardSuccessToastMessage'));
        return of(true);
      default:
        return of(false);
    }
  }
}
