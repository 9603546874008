import { gql } from 'apollo-angular';

export type RequestSupportMutationResponse =
  | { __typename: 'RequestSupport'; accepted: boolean }
  | { __typename: 'RequestSupportError'; reason: string };

export const requestSupportMutation = gql`
  mutation requestSupportMutation($customerId: UUID, $solutionId: UUID) {
    requestSupport(input: { customerId: $customerId, solutionId: $solutionId }) {
      ... on RequestSupport {
        __typename
        accepted
      }
      ... on RequestSupportError {
        __typename
        reason
      }
    }
  }
`;
