import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionId } from '@exb/jwt-utils';
import { PermissionsService } from '@exb/permissions';
import { filter, first, from, map, switchMap } from 'rxjs';

export type RedirectRouteConfig = {
  route: string;
  relative: boolean;
  permissions: PermissionId[];
};

const noPermissionsRoute: RedirectRouteConfig = {
  route: '/no-permission',
  relative: false,
  permissions: [],
};

export function redirectToBasedOnPermission(redirectRoutes: RedirectRouteConfig[]): CanActivateFn {
  return (_, state) => {
    const router = inject(Router);
    const permissionsService = inject(PermissionsService);

    return from([...redirectRoutes, noPermissionsRoute]).pipe(
      switchMap(({ route, relative, permissions }) => {
        return permissionsService
          .hasOneOfThesePermissions(permissions)
          .pipe(map(hasPermission => ({ route, relative: relative, hasPermission })));
      }),
      filter(({ hasPermission }) => hasPermission),
      first(),
      map(({ route, relative }) => {
        const url = relative ? `${state.url}/${route}` : route;
        return router.parseUrl(url);
      }),
    );
  };
}
