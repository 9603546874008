import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { PermissionsService } from '@exb/permissions';
import { TranslocoService } from '@jsverse/transloco';
import { MenuItem } from 'primeng/api';
import { Observable, combineLatest, map } from 'rxjs';

@Component({
  selector: 'exb-dm-doc-management',
  templateUrl: './doc-management.component.html',
  styleUrls: ['./doc-management.component.scss'],
})
export class DocManagementComponent {
  private readonly permissionsService = inject(PermissionsService);
  private readonly translocoService = inject(TranslocoService);

  private readonly documentListTab$: Observable<MenuItem> = combineLatest([
    this.permissionsService.hasOneOfThesePermissions([
      'document:read',
      'document:upload',
      'document:delete',
      'document:download',
    ]),
    this.translocoService.selectTranslate('documentManagement.docTab'),
  ]).pipe(
    map(([hasPermission, label]) => ({
      id: 'document-list-tab',
      label,
      routerLink: 'all',
      visible: hasPermission,
    })),
  );

  private readonly documentSetsTab$: Observable<MenuItem> = combineLatest([
    this.permissionsService.hasOneOfThesePermissions([
      'document_set:read',
      'document_set:create',
      'document_set:update',
      'document_set:delete',
    ]),
    this.translocoService.selectTranslate('documentManagement.setsTab'),
  ]).pipe(
    map(([hasPermission, label]) => ({
      id: 'document-set-list-tab',
      label,
      routerLink: 'sets',
      visible: hasPermission,
    })),
  );

  protected readonly tabItems = toSignal(
    combineLatest<MenuItem[]>([this.documentListTab$, this.documentSetsTab$]).pipe(
      map(items => items.filter(item => item.visible !== false)),
    ),
    {
      initialValue: [],
    },
  );
}
