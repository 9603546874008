<ul class="list-none text-moderate label-01 m-0 p-0 mb-5" *transloco="let t">
  @for (status of workAreaStatuses; track status.id) {
    <li [attr.data-testid]="status.id + '-status'">
      <i
        class="mr-1"
        [ngClass]="status.isAvailable ? 'material-icons-round mi-check-circle' : 'material-icons-outlined mi-cancel'"
        [class.text-muted]="!status.isAvailable"></i>
      {{ status.label }}
    </li>
  }
</ul>
<p
  exbEndTruncate
  [linesClamped]="descriptionClampedLines"
  class="text-moderate body-03 m-0"
  data-testid="solution-description">
  {{ solutionDescription }}
</p>
