<div class="flex flex-column h-full" *transloco="let t">
  <exb-app-header>
    <div class="flex flex-row align-items-center">
      <button
        pButton
        pRipple
        type="button"
        data-testid="doc-browser-back-btn"
        routerLink=".."
        icon="material-icons-round mi-arrow-back"
        class="p-button-text mr-2"></button>
      <h2 class="heading-02 m-0" data-testid="doc-management-title">{{ t('documentManagement.title') }}</h2>
    </div>
  </exb-app-header>
  <p-tabMenu [model]="tabItems()" data-testid="doc-management-tabs" class="panel-position">
    <ng-template pTemplate="item" let-item>
      <a class="p-menuitem-link" [routerLink]="item.routerLink" routerLinkActive="active">
        <span [attr.data-testid]="item.id">{{ item.label }}</span>
      </a>
    </ng-template>
  </p-tabMenu>

  <div class="flex-1 overflow-auto px-4">
    <router-outlet></router-outlet>
  </div>
</div>
