<div class="flex flex-column h-full" *transloco="let t">
  <exb-app-header [hasBottomBorder]="isOnScrollMode">
    <div class="flex flex-row justify-content-between">
      <h1 class="heading-01 m-0">{{ t('solutions.title') }}</h1>
      <form class="flex align-items-center" [formGroup]="form" (ngSubmit)="focusFirstSolution()">
        <p-dropdown
          [options]="(options$ | async) || []"
          formControlName="sort"
          optionLabel="name"
          optionValue="code"
          class="mr-2"
          styleClass="p-dropdown-sm"></p-dropdown>
        <exb-search-input
          data-testid="search-solutions"
          [placeholder]="t('moduleEditor.modules.search')"
          formControlName="search"
          size="md"></exb-search-input>
        <button
          data-testid="create-solution-button"
          pButton
          pRipple
          type="button"
          icon="material-icons-round mi-add"
          class="mx-2 mb-0"
          *exbHasOneOfThesePermissions="['solution:create']"
          (click)="onCreateSolution()"
          [label]="t('solutionBrowser.createSolution.buttonLabel')"></button>
      </form>
    </div>
    <div class="flex flex-row align-items-baseline">
      <h5 class="body-02 text-highlight m-0" data-testid="customer-name">
        {{ customer.name }}
      </h5>
      <button
        pButton
        class="p-button-link px-2"
        *ngIf="canChangeCustomer$ | async"
        (click)="selectDifferentCustomer()"
        data-testid="change-customer-button">
        {{ t('solutions.changeCustomerLink') }}
      </button>
    </div>
  </exb-app-header>
  <div *ngIf="emptyStateMessage$ | async as emptyStateMessage" class="flex flex-grow-1">
    <exb-empty-status [status]="emptyStateMessage" class="m-auto">
      <button
        *ngIf="emptyStateMessage.action"
        data-testid="alt-create-solution-button"
        pButton
        pRipple
        type="button"
        [icon]="emptyStateMessage.action!.icon!"
        class="mx-2 mb-0"
        (click)="onCreateSolution()"
        [label]="emptyStateMessage.action!.label"></button>
    </exb-empty-status>
  </div>

  <div class="flex flex-wrap flex-row my-0 mx-4 pb-2 gap-4 overflow-auto" (scroll)="onScroll($event)">
    <exb-solution-card
      *ngFor="let solution of solutionList; let i = index; trackBy: trackById"
      [solution]="solution"
      [attr.data-testid]="'solution-card-' + i"></exb-solution-card>
  </div>
</div>
