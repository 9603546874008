import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import { ApolloProviders } from '@exb/graphql';

import { RequestSupportMutationResponse, requestSupportMutation } from './support.service.query';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private readonly apollo = inject(Apollo);

  requestSupport({ customerId, solutionId }: { customerId?: string; solutionId?: string } = {}): Observable<boolean> {
    return this.apollo
      .use(ApolloProviders.nextApiMutation.clientName)
      .mutate<{ requestSupport: RequestSupportMutationResponse }>({
        mutation: requestSupportMutation,
        variables: { customerId, solutionId },
      })
      .pipe(
        map(({ data }) => {
          if (data?.requestSupport.__typename === 'RequestSupport') {
            return data.requestSupport.accepted;
          }
          throw new Error(data?.requestSupport.__typename || 'Empty response from the server!');
        }),
      );
  }
}
