import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppHeaderComponent } from '@exb/components';
import { DeleteDocumentService } from '@exb/document';
import { DMTableFilterModule } from '@exb/document-list';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { DocManagementComponent } from './doc-management.component';

@NgModule({
  declarations: [DocManagementComponent],
  imports: [
    AppHeaderComponent,
    ButtonModule,
    CommonModule,
    DMTableFilterModule,
    RouterModule,
    TabMenuModule,
    TabViewModule,
    TranslocoModule,
  ],
  providers: [DeleteDocumentService],
})
export class DocManagementModule {}
