import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionId, PermissionsService } from '@exb/permissions';
import { Solution } from '@exb/solution';
import { ExtractionResultService } from '@exb/workflow';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { CardModule } from 'primeng/card';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { SolutionCardBodyComponent, WorkAreaStatus } from './solution-card-body/solution-card-body.component';
import { SolutionCardFooterComponent } from './solution-card-footer/solution-card-footer.component';
import { SolutionCardHeaderComponent } from './solution-card-header/solution-card-header.component';

@Component({
  selector: 'exb-solution-card',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    TranslocoModule,
    SolutionCardHeaderComponent,
    SolutionCardBodyComponent,
    SolutionCardFooterComponent,
  ],
  templateUrl: './solution-card.component.html',
  styleUrls: ['./solution-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionCardComponent {
  readonly solution = input.required<Solution>();

  readonly descriptionClampedLines$: Observable<number>;
  readonly $isHeaderMultiline = new Subject<boolean>();

  canViewResults$!: Observable<boolean>;
  workAreaStatuses$: Observable<WorkAreaStatus[]>;

  constructor(
    private readonly extractionResultService: ExtractionResultService,
    private readonly permissionsService: PermissionsService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translocoService: TranslocoService,
  ) {
    this.descriptionClampedLines$ = this.$isHeaderMultiline.pipe(
      distinctUntilChanged(),
      map(isHeaderMultiline => (isHeaderMultiline ? 2 : 3)),
    );

    this.workAreaStatuses$ = toObservable(this.solution).pipe(
      switchMap(solution => {
        const areResultsReady$ = of(solution).pipe(
          switchMap(solution => {
            if (solution.workflowId && solution.workflowVersion !== undefined) {
              return this.extractionResultService
                .watchOverallExtractionStatus(solution.workflowId, solution.workflowVersion, {
                  solutionId: solution.id,
                })
                .pipe(map(status => status === 'available'));
            }
            return of(false);
          }),
          startWith(false),
          distinctUntilChanged(),
        );

        const buildWorkAreaStatus = ({
          id,
          labelKey,
          permissions,
          isAvailable$,
        }: {
          id: string;
          labelKey: string;
          permissions: PermissionId[];
          isAvailable$: Observable<boolean>;
        }): Observable<WorkAreaStatus | undefined> => {
          return this.permissionsService.hasOneOfThesePermissions(permissions, { solutionId: solution.id }).pipe(
            switchMap(hasPermission => {
              if (!hasPermission) {
                return of(undefined);
              }
              return combineLatest([this.translocoService.selectTranslate(labelKey), isAvailable$]).pipe(
                map(([label, isAvailable]) => ({ id, label, isAvailable })),
              );
            }),
          );
        };

        return combineLatest([
          buildWorkAreaStatus({
            id: 'documents',
            labelKey: marker('solutionBrowser.solutionCard.body.documents'),
            permissions: ['document:read', 'document:upload', 'document:delete', 'document:download'],
            isAvailable$: of(!!solution.documentCount),
          }),
          buildWorkAreaStatus({
            id: 'dem',
            labelKey: marker('solutionBrowser.solutionCard.body.dem'),
            permissions: ['dem:read', 'dem:update'],
            isAvailable$: of(!!solution.workflowId),
          }),
          buildWorkAreaStatus({
            id: 'workflow',
            labelKey: marker('solutionBrowser.solutionCard.body.workflow'),
            permissions: ['workflow:read', 'workflow:execute', 'workflow:update'],
            isAvailable$: of(!!solution.workflowId),
          }),
          buildWorkAreaStatus({
            id: 'gold-data',
            labelKey: marker('solutionBrowser.solutionCard.body.goldData'),
            permissions: [
              'gold_data:read',
              'gold_data:select',
              'gold_data:annotate',
              'gold_data:approve',
              'gold_data:prefill',
              'gold_data:download',
            ],
            isAvailable$: of(!!solution.goldData?.solutionId),
          }),
          buildWorkAreaStatus({
            id: 'results',
            labelKey: marker('solutionBrowser.solutionCard.body.results'),
            permissions: ['results:read'],
            isAvailable$: areResultsReady$,
          }),
          buildWorkAreaStatus({
            id: 'e2e-measurements',
            labelKey: marker('solutionBrowser.solutionCard.body.e2eMeasurement'),
            permissions: ['e2e_measurement:read', 'e2e_measurement:create'],
            isAvailable$: of(!!solution.e2eMeasurements),
          }),
        ]);
      }),
      map(statuses => statuses.filter(status => !!status)),
      shareReplay(1),
    );

    this.canViewResults$ = this.workAreaStatuses$.pipe(
      map(statuses => !!statuses.find(status => status.id === 'results' && status.isAvailable)),
    );
  }

  navigateToSolution() {
    this.router.navigate([this.solution().id], { relativeTo: this.activatedRoute });
  }

  navigateToResults() {
    this.router.navigate([this.solution().id, 'result-extraction'], {
      relativeTo: this.activatedRoute,
    });
  }
}
