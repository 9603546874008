import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';

import { AppHeaderComponent, EmptyStatusComponent, SearchInputComponent } from '@exb/components';
import { HasOneOfThesePermissionsDirective } from '@exb/permissions';

import { SolutionBrowserComponent } from './solution-browser.component';
import { SolutionCardComponent } from './solution-card/solution-card.component';

@NgModule({
  declarations: [SolutionBrowserComponent],
  imports: [
    AppHeaderComponent,
    ButtonModule,
    CommonModule,
    DropdownModule,
    DynamicDialogModule,
    EmptyStatusComponent,
    HasOneOfThesePermissionsDirective,
    FormsModule,
    InputTextModule,
    MenuModule,
    ReactiveFormsModule,
    SearchInputComponent,
    SolutionCardComponent,
    TableModule,
    TranslocoModule,
  ],
  providers: [DialogService],
})
export class SolutionBrowserModule {}
