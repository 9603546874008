import { NgModule, inject } from '@angular/core';
import {
  ResolveData,
  RouterModule,
  provideRouter,
  withComponentInputBinding,
  withViewTransitions,
} from '@angular/router';
import { AuthenticationService } from '@exb/auth';
import { ApiService } from '@exb/config';
import { Customer, customerResolver } from '@exb/customer';
import {
  documentDetailsResolver,
  documentFilterResolver,
  documentSetResolver,
  documentSortResolver,
} from '@exb/document';
import { DocumentDetailsComponent } from '@exb/document-details';
import { DocumentListComponent } from '@exb/document-list';
import { DocumentResultComponent } from '@exb/document-result';
import { DetailedDocSetComponent } from '@exb/document-set-details';
import { DocumentSetListComponent } from '@exb/document-set-list';
import { DocumentViewLayoutComponent } from '@exb/document-view-layout';
import { LoggingService } from '@exb/logging';
import { createPermissionGuard } from '@exb/router-utils';
import { SolutionStateService } from '@exb/solution';
import { SolutionDashboardComponent } from '@exb/solution-dashboard';
import { translate } from '@jsverse/transloco';
import { marker } from '@jsverse/transloco-keys-manager/marker';
import { WINDOW } from '@ng-web-apis/common';
import { catchError, map, of, tap } from 'rxjs';
import { MainLayoutComponent } from './components/main-layout';
import {
  canActivateDocManagementWithNoParams,
  canActivateDocSetManagementWithNoParams,
  canActivateProtectedPage,
  redirectToBasedOnPermission,
  solutionExistsGuard,
} from './guards';
import { CustomRoute } from './models/routing/route.model';
import { SignInComponent, SignUpComponent } from './pages/authenticate';
import { DEMComponent } from './pages/dem';
import { DocManagementComponent } from './pages/document-management-layout';
import { NotFoundComponent } from './pages/not-found';
import { NotificationComponent } from './pages/notification';
import { ProfileComponent } from './pages/profile';
import { SolutionBrowserComponent } from './pages/solution-browser';
import { SupportRequestComponent } from './pages/support-request/support-request.component';
import { DEMGuardService } from './services';

const documentViewerRoute: CustomRoute = {
  path: 'document/:documentId',
  component: DocumentViewLayoutComponent,
  resolve: { filter: documentFilterResolver, sort: documentSortResolver },
  data: {
    title: {
      breadcrumb: undefined,
    },
  },
  runGuardsAndResolvers: 'always',
  children: [
    { path: '', redirectTo: 'details', pathMatch: 'full' },
    {
      path: 'details',
      component: DocumentDetailsComponent,
      resolve: { details: documentDetailsResolver },
      data: {
        title: marker('solutions.docManagement.viewer.breadcrumb'),
      },
      runGuardsAndResolvers: 'always',
    },
    {
      path: 'results',
      component: DocumentResultComponent,
      canActivate: [createPermissionGuard(['results:read'])],
      data: {
        title: marker('solutions.docManagement.result.breadcrumb'),
      },
      runGuardsAndResolvers: 'always',
    },
  ],
};

const demRoute: CustomRoute = {
  path: 'dem',
  component: DEMComponent,
  data: { title: marker('dem.title') },
  resolve: { filter: documentFilterResolver, sort: documentSortResolver },
  runGuardsAndResolvers: 'always',
  canActivate: [createPermissionGuard(['dem:read', 'dem:update'])],
  canDeactivate: [DEMGuardService],
};

const resultExtractionRoute: CustomRoute = {
  path: 'result-extraction',
  data: { title: marker('solutions.resultExtraction') },
  canActivate: [createPermissionGuard(['results:read', 'workflow:execute'])],
  loadChildren: () => import('@exb/result-viewer').then(m => m.ResultExtractionRoutingModule),
};

const routes: CustomRoute[] = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: 'signup', component: SignUpComponent },
  { path: 'signin', component: SignInComponent },
  {
    path: 'main',
    component: MainLayoutComponent,
    data: { title: { page: marker('appName') } },
    canActivate: [canActivateProtectedPage],
    children: [
      {
        path: 'customers',
        data: { title: { breadcrumb: marker('appName') } },
        children: [
          {
            path: '',
            loadChildren: () => import('@exb/customer-selection').then(m => m.CustomerSelectionRoutingModule),
          },
          {
            path: ':customerId',
            resolve: { customer: customerResolver },
            data: { title: { page: ({ customer }: { customer: Customer }) => customer.name } },
            canActivate: [createPermissionGuard(['customer:read'], true)],
            children: [
              { path: '', redirectTo: 'solutions', pathMatch: 'full' },
              {
                path: 'solutions',
                data: {
                  title: {
                    breadcrumb: ({ customer }: { customer: Customer }) =>
                      `${customer.name} ${translate('solutions.title')}`,
                  },
                },
                children: [
                  { path: '', component: SolutionBrowserComponent },
                  {
                    path: ':solutionId',
                    resolve: { solution: () => inject(SolutionStateService).solution$ },
                    runGuardsAndResolvers: 'always',
                    canActivate: [createPermissionGuard('solution:read', true), solutionExistsGuard],
                    data: {
                      title: (data: ResolveData) => data['solution'].name,
                    },
                    children: [
                      { path: '', component: SolutionDashboardComponent },
                      resultExtractionRoute,
                      {
                        path: 'workflow',
                        canActivate: [createPermissionGuard(['workflow:read', 'workflow:update'])],
                        data: { title: marker('workflowEditor.title') },
                        loadChildren: () => import('@exb/poorman-workflow-editor').then(m => m.WorkflowEditorModule),
                      },
                      {
                        path: 'workflow-editor',
                        canActivate: [createPermissionGuard(['workflow:read', 'workflow:update'])],
                        data: { title: marker('workflowEditor.title') },
                        loadChildren: () => import('@exb/workflow-editor').then(m => m.VisualWorkflowEditorModule),
                      },
                      {
                        path: 'solution-gold-data',
                        canActivate: [
                          createPermissionGuard([
                            'gold_data:read',
                            'gold_data:select',
                            'gold_data:annotate',
                            'gold_data:approve',
                            'gold_data:prefill',
                            'gold_data:download',
                          ]),
                        ],
                        data: {
                          title: marker('solutions.goldData'),
                          preserveQueryParams: true,
                        },
                        loadChildren: () => import('@exb/gold-data-browser').then(m => m.GoldDataBrowserModule),
                      },
                      {
                        path: 'e2e-measurement',
                        data: { title: marker('solutions.e2e') },
                        canActivate: [createPermissionGuard(['e2e_measurement:read', 'e2e_measurement:create'])],
                        loadChildren: () => import('@exb/e2e-measurement-table').then(m => m.ROUTES),
                      },
                      {
                        path: 'api-credentials',
                        data: {
                          title: marker('solutions.apiCredentials'),
                        },
                        canActivate: [
                          createPermissionGuard([
                            'api_credential:read',
                            'api_credential:create',
                            'api_credential:delete',
                          ]),
                        ],
                        loadComponent: () => import('@exb/api-credential-manager').then(m => m.ApiCredentialsComponent),
                      },
                      {
                        path: 'service-test',
                        data: { title: marker('solutions.serviceTest') },
                        component: NotFoundComponent,
                      },
                      {
                        path: 'service-deployment',
                        data: {
                          title: marker('solutions.serviceDeployment'),
                        },
                        component: NotFoundComponent,
                      },
                      {
                        path: 'document-management',
                        data: {
                          title: marker('solutions.docManagement.breadcrumb'),
                        },
                        children: [
                          {
                            path: '',
                            component: DocManagementComponent,
                            data: { title: undefined },
                            children: [
                              {
                                path: '',
                                canActivate: [
                                  redirectToBasedOnPermission([
                                    {
                                      route: 'all',
                                      relative: true,
                                      permissions: [
                                        'document:read',
                                        'document:upload',
                                        'document:delete',
                                        'document:download',
                                      ],
                                    },
                                    {
                                      route: 'sets',
                                      relative: true,
                                      permissions: [
                                        'document_set:read',
                                        'document_set:create',
                                        'document_set:update',
                                        'document_set:delete',
                                      ],
                                    },
                                  ]),
                                ],
                                children: [],
                                pathMatch: 'full',
                              },
                              {
                                path: 'all',
                                component: DocumentListComponent,
                                canActivate: [
                                  createPermissionGuard([
                                    'document:read',
                                    'document:upload',
                                    'document:delete',
                                    'document:download',
                                  ]),
                                  canActivateDocManagementWithNoParams,
                                ],
                              },
                              {
                                path: 'sets',
                                canActivate: [
                                  createPermissionGuard([
                                    'document_set:read',
                                    'document_set:create',
                                    'document_set:update',
                                    'document_set:delete',
                                  ]),
                                ],
                                component: DocumentSetListComponent,
                              },
                            ],
                          },
                          documentViewerRoute,
                          demRoute,
                          resultExtractionRoute,
                          {
                            path: 'sets/:documentSetId',
                            data: {
                              title: ({ documentSet }: ResolveData) => documentSet.name,
                            },
                            canActivate: [
                              createPermissionGuard([
                                'document_set:read',
                                'document_set:update',
                                'document_set:delete',
                              ]),
                            ],
                            resolve: { documentSet: documentSetResolver },
                            children: [
                              {
                                path: '',
                                component: DetailedDocSetComponent,
                                canActivate: [canActivateDocSetManagementWithNoParams],
                              },
                              documentViewerRoute,
                              demRoute,
                              resultExtractionRoute,
                            ],
                          },
                        ],
                      },
                      {
                        ...demRoute,
                        data: {
                          ...demRoute.data,
                          shouldShowDocumentSetSelector: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      { path: '', redirectTo: 'customers', pathMatch: 'full' },
      {
        path: 'customer-overview',
        data: { title: marker('customerOverview.title') },
        loadChildren: () => import('@exb/customer-overview').then(m => m.CustomerOverviewModule),
      },
      {
        path: 'modules',
        data: { title: marker('moduleEditor.title') },
        canActivate: [() => inject(AuthenticationService).userInfo$.pipe(map(userInfo => !!userInfo?.isStaff))],
        loadChildren: () => import('@exb/module-editor').then(m => m.ModuleEditorModule),
      },
      {
        path: 'notifications',
        component: NotificationComponent,
        data: { title: marker('notifications.title') },
      },
      {
        path: 'profile',
        component: ProfileComponent,
        data: { title: marker('profile.title') },
      },
      {
        path: 'support-request',
        component: SupportRequestComponent,
        data: { title: { breadcrumb: marker('appName') } },
      },
    ],
  },
  {
    path: 'redirect-to-admin-backend',
    canActivate: [
      () => {
        const apiService: ApiService = inject(ApiService);
        const window: Window = inject(WINDOW);
        const loggingService = inject(LoggingService);
        return apiService.requestOTPUrl().pipe(
          tap(url => window.location.replace(url)),
          map(() => false),
          catchError(error => {
            loggingService.error('Error when accessing admin panel', error);
            return of(false);
          }),
        );
      },
    ],
    // h4ck for creating a route without component
    children: [],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', bindToComponentInputs: true })],
  providers: [DEMGuardService, provideRouter(routes, withViewTransitions(), withComponentInputBinding())],
  exports: [RouterModule],
})
export class AppRoutingModule {}
